import { Box, Link, SvgIcon, Typography } from '@mui/material';

import { ReactComponent as CodilityIcon } from '../../../assets/codility-icon.svg';

export const CodilityDescription = () => {
  return (
    <Box display="flex" alignItems="center">
      <SvgIcon inheritViewBox component={CodilityIcon} />
      <Typography variant="body2" color="text.secondary" ml={2}>
        Coding challenge will take place on the Codility platform <br /> right
        in your browser.{' '}
        <Link
          href="https://app.codility.com/demo/take-sample-test/"
          target="_blank"
          color="text.brand"
        >
          See what to expect
        </Link>
        .
      </Typography>
    </Box>
  );
};
